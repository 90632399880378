/**
 * Created by dev on 4.7.17.
 */
(function () {
    'use strict';
    var location = window.location.hash.substr(1);

    //tabs widget for solutions section
    $('.solutions-section-tab-control').click(function (event) {
        var id = event.target.id;
        setActiveSolutionsTab(id);
        setActiveTabContent(id);
        setActiveSolutionsContent(id);
    });

    $('.solution-switcher').click(function (event) {
        var id = event.target.id;
        setActiveSolutionsTab(id);
        setActiveTabContent(id);
        setActiveSolutionsContent(id);
        setSolutionLocationHash(id, null);
    });

    //tabs widget for solutions section
    $('.solutions-submenu li').click(function (event) {
        var id = event.target.id.slice(0, 4);
        setActiveSolutionsTab(id);
        setActiveTabContent(id);
        setActiveSolutionsContent(id);
    });
})();

var solutionTabs = {
    'tab1': {
        hash: 'manufacturers',
        imageSrc: '../images/solution/BLI_solutions_manufacturer_v1.jpg'
    },
    'tab2': {
        hash: 'full-service-distributors',
        imageSrc: '../images/solution/BLI_solutions_distributor_v1.jpg'
    },
    'tab3': {
        hash: 'independent-dealers',
        imageSrc: '../images/solution/BLI_solutions_dealer_v1.jpg'
    }
}

function setSolutionLocationHash (id, stateName) {
    var hash;
    if(stateName) 
    {
        location.hash = stateName;
        return;
    }

    if(!id || !solutionTabs[id])
    {
        return;        
    }

    location.hash = solutionTabs[id].hash;
}

function setActiveSolutionsTab(tab) {
    $.each($('.solutions-section-tab-control'), function () {
        return this.id === tab ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setActiveTabContent(tab) {
    $.each($('.tab-content'), function () {
        return this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setActiveSolutionsContent(tab) {
    //display active heading
    $.each($('.solutions-switch-content-section .transparent-heading'), function () {
        this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });
    //display active menu item
    $.each($('.solution-switcher'), function () {
        this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });
    //display active section block
    $.each($('.solutions-subsection'), function () {
        this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });

    // change the image preview and text
    //var imageSrc, firstSectionText, secondSectionText, thirdSectionText, fourthSectionText;
    //changing sections text happens here
    // if(tab === 'tab2'){ //if selected tab is "full service distributors"
    //     imageSrc = '../images/solution/BLI_solutions_distributor_v1.jpg';
    //     firstSectionText = 'Analyze your best dealers and prospects by performance and location – find where your best customers are, and discover where opportunities to increase your market share and penetration lie within your current dealer and customer base.';
    //     secondSectionText = 'Customize an interactive portal for your professional customers, track their sales and interest history, and reach out with targeted, high-value execution strategies.';
    //     thirdSectionText = 'Smart distribution is good distribution. Keep your finger on the pulse of everything happening in the industry within your region.';
    //     fourthSectionText = 'Don’t leave a customer wanting. See the total flow of your products through your dealers to end customer, and make sure your dealers are stocked with the right products at the right time to drive up market share';
    // } else if (tab === 'tab3') { //if selected tab is "independent dealers"
    //     imageSrc = '../images/solution/BLI_solutions_dealer_v1.jpg';
    //     firstSectionText = 'Analyze your highest value customers and prospects for your area. Make the best decisions on products to attract the highest-value customers, and find more like them.';
    //     secondSectionText = 'Track relationships with your best customers and give them a customized viewpoint into your products and availability.';
    //     thirdSectionText = 'Keep your finger on the pulse of everything happening in the industry within your area.';
    //     fourthSectionText = 'Understand what you have on hand, what the highest-value customers want, and take action to make sure you have the right products at the right time to make the most sales.';
    // } else { //if selected tab is "manufacturers"
    //     imageSrc = '../images/solution/BLI_solutions_manufacturer_v1.jpg';
    //     firstSectionText = 'Analyze your best customers and prospects by value and location – find your highest performing retailers and products, and where your opportunities for growth lie within your current dealer and customer base.';
    //     secondSectionText = 'Understand what your customer wants and show them what they need – Track, target and execute loyalty and reach-out campaigns to your highest-value customers, dormant customers, and potentials.';
    //     thirdSectionText = 'Keep ahead of other OEMs by tracking the pulse of happenings in your industry and regions.';
    //     fourthSectionText = 'Understand the total flow of your products through distributors and dealers to end customer, helping you have the right products in the right place at the right time for the right customers.';
    // }
    //console.log('changing txt');
    //console.log(firstSectionText);
    // $('#solutions-subsection-text-first').html(firstSectionText);
    // $('#mobile-solutions-subsection-text-first').html(firstSectionText);
    // $('#solutions-subsection-text-second').html(secondSectionText);
    // $('#solutions-subsection-text-third').html(thirdSectionText);
    // $('#solutions-subsection-text-fourth').html(fourthSectionText);
    // $('#mobile-solutions-subsection-text-second').html(secondSectionText);
    // $('#mobile-solutions-subsection-text-third').html(thirdSectionText);
    // $('#mobile-solutions-subsection-text-fourth').html(fourthSectionText);
    $(".solutions-thumb").attr("src", solutionTabs[tab] ? solutionTabs[tab].imageSrc :  solutionTabs[Object.keys(solutionTabs)[0]].imageSrc);

    //change text
    //var selectedText = $('.solutions-switch-content-section .transparent-heading.active').text();
    //$('.solutions-selected-text').text(selectedText);
}