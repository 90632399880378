/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function () {
    'use strict';
    // Your custom JavaScript goes here
    //header restyling on scroll
    /*   $(window).on("scroll", function () {
     if ($(this).scrollTop() > 650) {
     $(".header").addClass("not-transparent");
     }
     else {
     $(".header").removeClass("not-transparent");
     }
     });*/

    //header submenu - hide on overlay
    $(window).click(function () {
        return $('.bli-header-nav-submenu').hide()
    });

    $(document).ready(function () {
        // handle templates TODO it's temporary
        loadIfEmpty('#header', './partials/header.html');
        loadIfEmpty('#footer', './partials/footer.html', () => getCurrentYear());
        loadIfEmpty('#post-sidenav', '../../partials/sidenav.html');

        loadIfEmpty('#post-header', '../../partials/header.html');
        loadIfEmpty('#post-footer', '../../partials/footer.html', () => getCurrentYear());
        loadIfEmpty('#sidenav', './partials/sidenav.html');

        $('.bxslider').bxSlider();

        loadBlogData();
    });

    //video player

    var secondaryVid = $('#secondary-video');

    $('#watch-video').click(function () {
        if (secondaryVid.get(0).paused) {
            secondaryVid[0].play();
            $('#secondary-video-control-play').hide();
        } else {
            secondaryVid[0].pause();
            $('#secondary-video-control-play').show();
        }
    });

    $('#action-button-video').click(function () {
        secondaryVid[0].play();
        $('#secondary-video-control-play').hide();
        $('#secondary-video-control-pause').show();
    });

    //tabs widget
    $('.section-tab-control').click(function (event) {
        var id = event.target.id;
        setActiveTab(id);
        setActiveTabContent(id);
    });

    //tabs widget for eye one
    $('.eye-one-list li').click(function (event) {
        var id = event.target.id;
        setActiveEyeOneTab(id);
        setActiveEyeOneContent(id);
        setControlStyle(id);
    });

    $('.eye-one-arrow-control').click(function (event) {
        //var id = $('.eye-one-list li.active').attr('id');
        //console.log(id);
        setActiveEyeOneTab(id);
        setActiveEyeOneContent(id);
        setControlStyle(id);
    });

    $("#demo-form").bind('ajax:complete', function () {

        // tasks to do
        window.location.href = '../thank-you';


    });

    /*  $("#share").jsSocials({
          shares: ["email", "twitter", "facebook", "googleplus", "linkedin", "pinterest", "stumbleupon", "whatsapp"]
      });*/
})();

function setActiveTab(tab) {
    $.each($('.section-tab-control'), function () {
        return this.id === tab ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setActiveTabContent(tab) {
    $.each($('.tab-content'), function () {
        return this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setActiveEyeOneTab(tab) {
    $.each($('.eye-one-list li'), function () {
        return this.id === tab ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setActiveEyeOneContent(tab) {
    $.each($('.eyeone-tab-content'), function () {
        return this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setControlStyle(tab) {
    //console.log(tab.indexOf('1'));
    if (tab.indexOf('1') > -1) {
        $('.eye-one-arrow-control-left').addClass('disabled');
        $('.eye-one-arrow-control-right').removeClass('disabled');
    } else if (tab.indexOf('4') > -1) {
        $('.eye-one-arrow-control-left').removeClass('disabled');
        $('.eye-one-arrow-control-right').addClass('disabled');
    }
}

function openNav() {
    $('.sidenav-container').css("right", '0');
    //$('#main-container').css("margin-right", '250px');
}

function closeNav() {
    $('.sidenav-container').css("right", '-260px');
    //$('#main-container').css("margin-right", '0');
}

function openSubMenu(event) {
    event.stopPropagation();
    return $('.bli-header-nav-submenu').toggle();
}

function loadBlogData() {
    var inHtml = '', mobHtml = '', newItem, newMobileItem;
    $.get("/api/posts", (data) => {

        $.each(data.posts, function (index, value) {
            if (window.moment) {
                var publishedMoment = moment(value.publishedDate).format("MMMM Do, YYYY");
            }
            //console.log(value);
            newItem = `<div class="section-column">
                        <div class="section-column-content">
                            <div class="section-column-heading">
                                <h4>${value.title}</h4>
                            </div>                      
                            <div class="section-text underlined-title">
                                ${value.author.name.first} ${value.author.name.last}
                            </div>
                            <div class="section-text description-text">
                                ${value.content.brief}
                            </div>
                            <div class="section-action-button-container">
                                <a class="action-button" href="/blog/post/${value.slug}">read full entry</a>
                            </div>
                        </div>
                      </div>`;

            newMobileItem = `
                <li>
                    <div class="section-column-content">
                        <div class="section-column-heading">
                            <h4>${value.title}</h4>
                        </div>
                        <div class="section-text underlined-title">
                            ${value.author.name.first} ${value.author.name.last}
                        </div>
                        <div class="section-text description-text">
                            ${value.content.brief}
                        </div>
                        <div class="section-action-button-container">
                            <a class="action-button" href="/blog/post/${value.slug}">read full entry</a>
                        </div>
                    </div>
                </li>`;

            inHtml += newItem;
            mobHtml += newMobileItem;
        });

        $('.blog-container').html(inHtml);
        $('.mobile-blog-container').html('<ul class="bxslider">' + mobHtml + '</ul>');
    })
        .fail(() => {
            alert("An error occured while get data from db");

            inHtml = inHtml = 'An error occured. Please reload page after a while';
            $('.blog-container').html(inHtml);
            $('.mobile-blog-container').html('<ul class="bxslider">' + inHtml + '</ul>');
        });
};

function loadIfEmpty(selector, path, fn) {
    if (!selector || !path) {
        return;
    }

    let element = $(selector);
    if (element && !element.html()) {
        element.load(path, fn);
        return;
    }

    if (!element) {
        return;
    }

    if (fn) {
        fn();
    }
}

function validateDemoForm() {
    let msgBox = document.getElementById('msgBox');
    msgBox.innerHTML = "";

    return checkboxDemoValidate();
}

function checkboxDemoValidate() {
    let checkedProducts = document.querySelectorAll('.demo-checkbox-group input[type="checkbox"]');
    if (checkedProducts.length <= 0) {
        return;
    }

    if (Array.prototype.slice.call(checkedProducts).some(x => x.checked)) {
        checkedProducts.forEach(checkedProduct => removeClass(checkedProduct, 'invalid'));
        msgBox.innerHTML = '';
        return true;
    }

    checkedProducts.forEach(checkedProduct => addClass(checkedProduct, 'invalid'));
    msgBox.innerHTML = 'Please check at least one Product';
    return false;
}

function getCurrentYear() {
    let year = new Date().getFullYear();
    let element = $('#current-year');
    if (element) {
        element.text(year);
    }
}

function addClass(element, name) {
    if (!element || !name) {
        return;
    }

    let classNames = element.className.split(' ');
    if (classNames.indexOf(name) !== -1) {
        return;
    }
    element.className += ' ' + name;
}

function removeClass(element, name) {
    if (!element || !name) {
        return;
    }

    element.className = element.className.replace(new RegExp(`\\b${name}\\b`, 'g'), '');
}

