/**
 * Created by dev on 4.7.17.
 */
(function () {
    'use strict';
    var location = window.location.hash.substr(1);
    var anchor = $('#eyeon-content');
    setActiveEyeon(location);

    if (location && anchor.length) {
        $('html,body').animate({scrollTop: anchor.offset().top}, 'slow');
    }

    $('.eyeon-tab-control').click(function (event) {
        var id = event.target.id;
        setEyeonLocationHash(id, null)
    });

    $('#eyeon-content video').off('play').on('play', function () {
        var current = this;
        $('#eyeon-content video').each(function () {
            if (current != this) {
                this.pause();
            }
        });
    });
})();

function setEyeonLocationHash(id, stateName) {
    if (stateName) {
        location.hash = stateName;
        return;
    }

    var hash = '';

    switch (id) {
        case 'tab1':
            hash = 'advanced';
            break;
        case 'tab2':
            hash = 'dealer';
            break;
        case 'tab3':
            hash = 'customer';
            break;
        case 'tab4':
            hash = 'promotions';
            break;
        case 'tab5':
            hash = 'retail';
            break;
    }

    location.hash = hash;
}

function selectEyeon(hash) {
    var anchor = $('#eyeon-content');
    setActiveEyeon(hash);
    if (anchor.offset()) {
        $('html,body').animate({scrollTop: anchor.offset().top}, 'slow');
    }
}

function setActiveEyeon(location) {
    location = location || '';

    var activeTab = 'tab2';
    switch (location.toLowerCase()) {
        case 'advanced':
            activeTab = 'tab1';
            break;
        case 'dealer':
            activeTab = 'tab2';
            break;
        case 'customer':
            activeTab = 'tab3';
            break;
        case 'promotions':
            activeTab = 'tab4';
            break;
        case 'retail':
            activeTab = 'tab5';
            break;
    }

    setActiveTab(activeTab);
    setActiveTabContent(activeTab);

}

function setActiveTab(tab) {
    pauseVideos();

    $.each($('.section-tab-control'), function () {
        return this.id === tab ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function setActiveTabContent(tab) {
    $.each($('.tab-content'), function () {
        return this.id.indexOf(tab) > -1 ? $(this).addClass('active') : $(this).removeClass('active');
    });
}

function pauseVideos() {
    $.each($('#eyeon-content video'), function (index, elem) {
        elem.pause();
    });
}


